var AFTER_LOGIN;
var AFTER_LOGIN_ID;

var handleProductView = function() {
	$(document).on('click', '[data-toggle="product-view"]', function(e) {
		e.preventDefault();
		
		var targetView = $(this).attr('data-view');
		var targetLi = $(this).closest('.nav-item');
		
		if (targetView == 'grid') {
			Cookies.set('productView', 'grid-view', { expires: 7, path: '' });
			$('.product-thumb-group').removeClass('list-view');
		} else if (targetView == 'list') {
			Cookies.set('productView', 'list-view', { expires: 7, path: '' });
			$('.product-thumb-group').addClass('list-view');
		}
		$('[data-toggle="product-view"]').closest('.nav-item').not(targetLi).removeClass('active');
		$(targetLi).addClass('active');
	});
};

var handleHomeCategoryHover = function() {
	$('.home-hero .category-list li:not(.has-sub) > a').hover(function() {
		$('.home-hero .category-list').addClass('without-sub');
	}, function() {
		$('.home-hero .category-list').removeClass('without-sub');
	});
};

var handleHeaderScroll = function() {
	//if ($(window).width() > 767) {
		var targetOffset = $('.app-header-nav').height();
		var targetHeight = $('.app-header').height() - $('.app-header-nav').height();
		var targetSmallHeight = targetOffset;
		
		var checkHeaderFixed = function() {
			var targetScrollTop = $(window).scrollTop();
			
			if (targetSmallHeight) {
				if (targetScrollTop > targetOffset) {
					$('.app').addClass('app-header-fixed');
					$('body').css('padding-top', targetHeight);
				} else if (targetScrollTop < targetSmallHeight) {
					$('.app').removeClass('app-header-fixed');
					$('body').css('padding-top', 0);
				}
			}
		};
		checkHeaderFixed();
		
		$(window).on('scroll', function() {
			checkHeaderFixed();
		});
	//}
};

var handleDeleteWishlist = function() {
	$(document).on('click', '[data-click="delete-wishlist"]', function(e) {
		e.preventDefault();

		var targetId = $(this).attr('data-id');
		var targetUrl = '/wishlist/delete/' + targetId;
		var targetFrom =  $(this).attr('data-from');
		var targetBtn = this;
		$.ajax({
			url: targetUrl,
			dataType: 'JSON',
			success: function(data) {
				if (data.success) {
					if (targetFrom == 'product-details') {
						$(targetBtn).html('<i class="far fa-heart fa-lg mr-1"></i> Add to Wishlist');
						$(targetBtn).attr('data-click','add-wishlist');
						$('[data-id="wishlist-msg"]').html('<span class="text-success"><i class="fa fa-check"></i> Successfully Removed</span>');
						setTimeout(function() {
							$('[data-id="wishlist-msg"] span').fadeOut();
						}, 1500);
					} else if (targetFrom == 'wishlist') {
						$(targetBtn).closest('tr').fadeOut(500, function() {
							$(this).remove();
							if ($('.table.table-order tbody tr').length == 0) {
								$('.table.table-order tbody').html('<tr><td colspan="5">No wishlist record found</td></tr>');
							}
						});
					}
				}
				if (data.login == false) {
					AFTER_LOGIN = 'wishlist-delete';
					AFTER_LOGIN_ID = targetId;
					$('#modalLoginRegister').modal('show');
				}
				if (data.error) {
					alert(data.error);
				}
			},
			error: function(data) {
				if (targetFrom == 'product-details') {
					$('[data-id="wishlist-msg"]').html('<span class="text-danger"><i class="fa fa-times"></i> Error occur while adding wish list</span>');
					setTimeout(function() {
						$('[data-id="wishlist-msg"] span').fadeOut();
					}, 1500);
				}
			}
		});
	});
};

var handleAddWishlist = function() {
	$(document).on('click', '[data-click="add-wishlist"]', function(e) {
		e.preventDefault();
		
		var targetId = $(this).attr('data-id');
		var targetUrl = '/wishlist/add/' + targetId;
		var targetFrom = $(this).attr('data-from');
		var targetBtn = this;
		$.ajax({
			url: targetUrl,
			dataType: 'JSON',
			success: function(data) {
				$(targetBtn).tooltip('dispose');
				
				if (data.success) {
					if (targetFrom == 'product-details') {
						$(targetBtn).html('<i class="fa fa-heart text-pink fa-lg mr-1"></i> Add to Wishlist');
						$(targetBtn).attr('data-click','delete-wishlist');
						$('[data-id="wishlist-msg"]').html('<span class="text-success"><i class="fa fa-check"></i> Successfully Added</span>');
						setTimeout(function() {
							$('[data-id="wishlist-msg"] span').fadeOut();
						}, 1500);
					}
				}
				if (data.error) {
					$(targetBtn).tooltip({
						title: data.error
					});
					$(targetBtn).tooltip('show');
				}
				if (data.login == false) {
					AFTER_LOGIN = 'wishlist-add';
					AFTER_LOGIN_ID = targetId;
					$('#modalLoginRegister').modal('show');
				}
			},
			error: function(data) {
				if (targetFrom == 'product-details') {
					$('[data-id="wishlist-msg"]').html('<span class="text-danger"><i class="fa fa-times"></i> Error occur while adding wish list</span>');
					setTimeout(function() {
						$('[data-id="wishlist-msg"] span').fadeOut();
					}, 1500);
				}
			}
		});
	});
};

var handleAddToCart = function() {
	$(document).on('click', '[data-click="add-to-cart"]', function(e) {
		e.preventDefault();
		var targetId = $(this).attr('data-id');
		var targetBtn = this;
		var targetFrom = $(this).attr('data-from');
		var targetQty = (targetFrom == 'product-details' || targetFrom == 'product-details-add-to-cart' || targetFrom == 'product-details-buy-now') ? $('[data-id="qty-input"]').val() : 1;
		var targetRedirect = $(this).attr('data-success-redirect');
		var targetTags = $(this).attr('data-tags');
		var targetAction = $(this).attr('data-action');
		var targetData = '';
		
		if (targetAction == 'add') {
			targetData = {
				add: { 
					id: targetId,
					qty: targetQty,
					tags: targetTags
				},
				from: targetFrom
			};
		} else if (targetAction == 'update') {
			targetData = {
				update: { 
					id: targetId,
					qty: targetQty,
					tags: targetTags
				},
				from: targetFrom
			};
		}
		if ($(targetBtn).hasClass('disabled')) {
			return;
		}
		$(targetBtn).addClass('disabled').html('<i class="fa fa-spin fa-spinner mr-1"></i> Adding...');
		
		$.ajax({
			url: '/user/cart/update',
			dataType: 'json',
			type: 'POST',
			data: targetData,
			success: function(data){
				setTimeout(function() {
					$(targetBtn).removeClass('disabled').html('ADD TO CART');
					$(targetBtn).tooltip('dispose');
					if (data.login === false) {
						if (data.login == false) {
							if (targetFrom == 'product-details-buy-now') {
								AFTER_LOGIN = 'add-to-cart-buy-now';
								AFTER_LOGIN_ID = targetId;
							} else {
								AFTER_LOGIN = 'add-to-cart';
								AFTER_LOGIN_ID = targetId;
							}
							$('#modalLoginRegister').modal('show');
						}
					}
					if (data.out_of_stock) {
						if (targetFrom == 'product-details') {
							$('[data-id="qty-text"]').html(0);
							$('[data-id="qty-input"]').attr('data-max-qty', '0');
							$('[data-id="qty-input"]').trigger('change');
						} else if (targetFrom == 'wishlist') {
							$('[data-target="stock"][data-id="'+ targetId +'"]').html(0);
							$(targetBtn).addClass('disabled btn-secondary').removeClass('btn-success').html('OUT OF STOCK');
						}
					}
					if (data.error) {
						$(targetBtn).tooltip({
							title: data.error,
							html: true
						});
						$(targetBtn).tooltip('show');
						return;
					}
					if (data.success) {
						if (data.total_cart_item) {
							if (targetRedirect) {
								window.location.href = targetRedirect;
							}
							if ($('#headerCart .cart-icon .cart-no').length === 0) {
								$('#headerCart .cart-icon').append('<span class="cart-no animated bounceIn">'+ data.total_cart_item +'</span>');
								setTimeout(function() {
									$('#headerCart .cart-icon .cart-no').removeClass('animated bounceIn');
								}, 500);
							} else {
								$('#headerCart .cart-icon .cart-no').html(data.total_cart_item).addClass('animated bounce');
								setTimeout(function() {
									$('#headerCart .cart-icon .cart-no').removeClass('animated bounce');
								}, 1500);
							}
						} else {
							$('#headerCart .cart-icon .cart-no').remove();
						}
					}
				}, 250);
			},
			error: function(data) {
				$(targetBtn).removeClass('disabled').html('ADD TO CART');
				alert('Something went wrong when adding items to your shopping cart.');
			}
		});
	});
};

var handleConfirmation = function() {
	
	$(document).on('click', '[data-toggle="confirmation"]', function(e) {
		e.preventDefault();
		
		var targetText = $(this).attr('data-text');
		var targetLink = $(this).attr('href');
		var x = confirm(targetText);
		
		if (x == true) {
			window.location.href= targetLink;
		}
	});
};


var handleScrollTo = function() {
	
	$(document).on('click', '[data-toggle="scroll-to"]', function(e) {
		e.preventDefault();
		
		var target = $(this).attr('data-target');
		console.log(target);
		$('html, body').animate({
			scrollTop: $(target).offset().top - 82
		});
	});
};

$(document).ready(function() {
	handleProductView();
	handleHomeCategoryHover();
	handleHeaderScroll();
	handleDeleteWishlist();
	handleAddWishlist();
	handleAddToCart();
	handleConfirmation();
	handleScrollTo();
});